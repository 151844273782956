import { createTheme } from "@mui/material/styles";
import { ptBR } from '@mui/material/locale';

export default createTheme({
  palette: {
    mode: 'dark'
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'standard'
      }
    }
  }
}, ptBR)
