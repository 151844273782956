import React, { useEffect, useState } from 'react';
import { Button, Container, Divider, FormControl, MenuItem, TextField, Typography } from '@mui/material';
import './App.css';
import { database } from './services/firebase';
import { addDoc, collection, onSnapshot, orderBy, query, Timestamp } from 'firebase/firestore';
import { format } from 'date-fns';
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid';

interface Data {
  email: string
  from: string
  name: string
  registration: string
  to: string
}

const App:React.FC = () => {
  const classes = ['A', 'B'];
  const [data, setData] = useState<Data>({
    email: '',
    from: 'A',
    name: '',
    registration: '',
    to: 'B'
  });
  const [gridRows, setGridRows] = useState<GridRowsProp>([]);
  
  const gridColumns:GridColDef[] = [
    { field: 'name', headerName: 'Nome', flex: 1 },
    { field: 'fromTo', headerName: 'De -> Para', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'createdAt', headerName: 'Requisição', flex: 1, align: 'center', headerAlign: 'center' }
  ];

  useEffect(() => {
    const queueRef = collection(database, 'queue');
    onSnapshot(query(queueRef, orderBy('created_at')), ({docs}) => {
      setGridRows(docs.map(doc => ({
        id: doc.get('registration'),
        name: doc.get('name'),
        fromTo: `${doc.get('from')} > ${doc.get('to')}`,
        createdAt: format(new Date(doc.get('created_at').seconds*1000), 'dd/MM/yyyy')
      })))
    })
  }, []);

  const handlerChange = ({target}: React.ChangeEvent<HTMLInputElement>) => {
    setData(prev => ({...prev, [target.name]: target.value}))
  }

  const validateEmail = React.useCallback(() => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(data.email);
  }, [data.email]);

  const submit = async () => {
    if (data.name.length > 10 && validateEmail() && data.registration.length > 5 && data.from !== data.to) {
      try {
        await addDoc(collection(database, 'queue'), {
          created_at: Timestamp.now(),
          ...data
        });
        setGridRows(prev => [
          ...prev,
          {
            createdAt: Timestamp.now().toDate(),
            id: data.registration,
            name: data.name,
            fromTo: `${data.from} > ${data.to}`
          }
        ]);
        setData({
          email: '',
          from: 'A',
          name: '',
          registration: '',
          to: 'B'
        });
        alert('Registro realizado com sucesso')
      } catch (e) {
        console.error('Error',e)
        alert('Não possível realizar o cadastro')
      }
    }
  }
  
  return (
    <Container>
      <Typography variant='h3' fontWeight='bold' textAlign='center' color='darkcyan'>Trocar de Turma</Typography>
      <FormControl sx={{ m: 1 }} fullWidth>
        <TextField
          required
          label='Nome completo'
          value={data.name}
          name='name'
          type='text'
          onChange={handlerChange}
          error={Boolean(data.name && data.name.length <= 10)}
        />
      </FormControl>
      <FormControl sx={{ m: 1 }} fullWidth>
        <TextField
          required
          label='E-mail'
          value={data.email}
          name='email'
          type='email'
          onChange={handlerChange}
          error={Boolean(data.email && !validateEmail())}
        />
      </FormControl>
      <FormControl sx={{ m: 1 }} fullWidth>
        <TextField
          required
          label='Matriculal'
          value={data.registration}
          name='registration'
          type='number'
          onChange={handlerChange}
          error={Boolean(data.registration && data.registration.length <= 5)}
        />
      </FormControl>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <FormControl sx={{ m: 1, width: '48%' }}>
          <TextField
            required
            select
            label='Turma atual'
            value={data.from}
            name='from'
            onChange={handlerChange}
          >
            {classes.map(item => (
              <MenuItem value={item} key={item}>{item}</MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl sx={{ m: 1, width: '48%' }}>
          <TextField
            required
            select
            label='Turma de Destino'
            value={data.to}
            name='to'
            onChange={handlerChange}
            error={data.from === data.to}
            helperText={data.from === data.to && 'A turma de destino não pode ser igual a turma atual'}
          >
            {classes.map(item => (
              <MenuItem value={item} key={item}>{item}</MenuItem>
            ))}
          </TextField>
        </FormControl>
      </div>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <FormControl sx={{ m: 1 }}>
          <Button
            variant='outlined'
            color='success'
            disabled={Boolean(
              !data.name || data.name.length <= 10 ||
              !data.email || !validateEmail() ||
              !data.registration || data.registration.length <= 5 ||
              data.from === data.to
            )}
            onClick={submit}
          >SALVAR</Button>
        </FormControl>
      </div>
      <Divider sx={{m: 1}} />
      <DataGrid
        rows={gridRows}
        columns={gridColumns}
        autoHeight
        initialState={{
          pagination: {
            pageSize: 25
          }
        }}
      />
    </Container>
  );
}

export default App;
