import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const {
  REACT_APP_API_KEY,
  REACT_APP_AUTH_DOMAIN,
  REACT_APP_PROJECT_ID,
  REACT_APP_APP_ID
} = process.env;

initializeApp({
  apiKey: REACT_APP_API_KEY,
  authDomain: REACT_APP_AUTH_DOMAIN,
  projectId: REACT_APP_PROJECT_ID,
  appId: REACT_APP_APP_ID
});

const database = getFirestore();

export { database }